<template>
  <v-col class="mt-3">
    <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="acceptanceDeadlineDate"
          v-bind="attrs"
          outlined
          label="Acceptance deadline"
          append-icon="mdi-calendar"
          readonly
          rounded
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="acceptanceDeadlineDate" scrollable min="2022-07-01" max="2022-12-31">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
        <!-- <v-btn text color="primary" @click="$refs.dialog.update(date)"> OK </v-btn> -->
        <v-btn text color="primary" @click="modal = false"> OK </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-col>
</template>

<script>
import { ref, computed } from '@vue/composition-api';

export default {
  props: {
    acceptanceDeadline: {
      type: String,
      default: '2022-12-31'
    }
  },
  setup(props, ctx) {
    const date = ref('');
    const modal = ref('');
    const acceptanceDeadlineDate = ref();
    const acceptanceDeadlineValue = computed({
      get: () => props.acceptanceDeadline,
      set: newVal => {
        acceptanceDeadlineDate.value = newVal;
      }
    });
    acceptanceDeadlineDate.value = acceptanceDeadlineValue.value;
    return { date, modal, acceptanceDeadlineDate };
  }
};
</script>
